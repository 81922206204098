import Cookies from "js-cookie"

export const industries = {
  REAL_ESTATE: "Bất động sản",
  FINANCE: "Tài chính",
  INSURANCE: "Bảo hiểm",
  EDUCATION: "Giáo dục",
  OTHER: "Khác",
  NULL: "Chưa rõ",
}

export const channels = [
  "GOOGLE_ADS",
  "FACEBOOK",
  "BLOG",
  "YOUTUBE",
  "LINKEDIN",
  "TELEMARKETING",
  "OTHER",
  "NULL",
]

export const contents = ["SCENARIO", "PROBLEM", "GUIDE", "NULL"]

export const marketers = ["HAO", "BANG", "HANH", "LIEN"]

export const checkParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const options = {
    expires: 7,
    domain: ".ucall.vn",
  }
  if (params["date"]) {
    Cookies.set("date", params["date"], options)
  }
  if (params["channel"]) {
    Cookies.set("channel", params["channel"], options)
  }
  if (params["marketer"]) {
    Cookies.set("marketer", params["marketer"], options)
  }
  if (params["label"]) {
    Cookies.set("label", params["label"], options)
  }
  if (params["content"]) {
    Cookies.set("content", params["content"], options)
  }
  if (params["contact"]) {
    Cookies.set("contact", params["contact"], options)
  }
}
