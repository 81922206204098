import firebase from "gatsby-plugin-firebase"
import { Button, Div, Input } from "atomize"
import { checkParams, industries } from "./constants"
import React, { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import { format } from "date-fns"

const LeadsForm = () => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [notes, setNotes] = useState("")
  const [industry, setIndustry] = useState("REAL_ESTATE")
  const submit = async () => {
    setLoading(true)
    const date = new Date()
    await axios.post("https://admin.ucall.vn/api/lead", {
      phone_number: phone,
      name,
      email,
      source: "LANDINGPAGE_FORM",
      sale_note: notes,
      date: Cookies.get("date")
        ? Cookies.get("date")
        : format(date, "LLLdd")?.toUpperCase(),
      industry: industry,
      channel: Cookies.get("channel") ? Cookies.get("channel") : "NULL",
      marketer: Cookies.get("marketer") ? Cookies.get("marketer") : undefined,
      label: Cookies.get("label") ? Cookies.get("label") : "NULL",
      content: Cookies.get("content") ? Cookies.get("content") : "NULL",
      contact: Cookies.get("contact") ? Cookies.get("contact") : "NULL",
      call_to_action: "FORM",
      // life_cycle: "LOST",
    })
    window.onbeforeunload = function () {
      window.scrollTo(0, 0)
    }
    window.location.href = "/thanks"
    setLoading(false)
  }

  useEffect(() => {
    checkParams()
  }, [])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        submit().then()
        firebase.analytics().logEvent("register_form")
      }}
    >
      <Input
        onChange={(value) => setName(value.target.value)}
        name="name"
        placeholder="Tên của bạn"
        m={{ b: "1rem" }}
      />
      <Input
        onChange={(value) => setEmail(value.target.value)}
        name="email"
        placeholder="Email"
        m={{ b: "1rem" }}
      />
      <Input
        onChange={(value) => setPhone(value.target.value)}
        name="phone"
        placeholder="Số điện thoại"
        m={{ b: "1rem" }}
      />

      <select
        name="industry"
        onChange={(value) => setIndustry(value.target.value)}
        style={{
          width: "100%",
          border: "1px solid rgb(201, 206, 214)",
          height: "2.5rem",
          paddingRight: "0.75rem",
          paddingLeft: "0.75rem",
          marginBottom: "1rem",
          borderRadius: "4px",
        }}
        value={industry}
      >
        {Object.keys(industries).map((_industry) => {
          return <option value={_industry}>{industries[_industry]}</option>
        })}
      </select>
      <Input
        onChange={(value) => setNotes(value.target.value)}
        name="notes"
        placeholder="Ghi chú"
        m={{ b: "1rem" }}
      />
      <Div d="flex" justify="flex-end">
        <Button type="submit" isLoading={loading} bg="info700">
          Đăng Ký
        </Button>
      </Div>
    </form>
  )
}

export default LeadsForm
